import React, { useRef, useState } from 'react';
import { logo } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isModelsOpen, setIsModelsOpen] = useState(false);
  const [isAnimalsOpen, setIsAnimalsOpen] = useState(false);
  const [isUzlukOpen, setIsUzlukOpen] = useState(false);
  const [isDoldurucuOpen, setIsDoldurucuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);
  const naviget = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };
  const toggleModelsSubMenu = () => {
    setIsModelsOpen(!isModelsOpen);
  };
  const toggleUzlukSubMenu = () => {
    setIsUzlukOpen(!isUzlukOpen);
  };
  const toggleDoldurucuSubMenu = () => {
    setIsDoldurucuOpen(!isDoldurucuOpen);
  };
  const toggleAnimalsSubMenu = () => {
    setIsAnimalsOpen(!isAnimalsOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <> 
      <div className='bg-[#E3D5F5] h-[75px] pt-5 mt-5'>
        <div className='max-w-container mx-auto h-[45px] flex justify-between'>
          <div className='flex justify-center items-center'>
          <nav>
  <ul className="hidden md:flex space-x-8 text-lg">
    <a href="/dashboard">
      <li>Sifarişlər</li>
    </a>
    <a href="/dashboard/contact">
      <li>Əlaqə</li>
    </a>
  </ul>
</nav>

          </div>
          <div className='flex items-center justify-center'>
        <div className="d-flex">
          <button  
            type="submit"
            onClick={logoutSubmit} className=" bg-[#5A639C] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full">Çıxış</button>
        </div>

      </div>
        </div>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-[black] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className='ml-6 md:ml-0 mt-3'>
            <a href='/'><img alt='logo' height={45} width={107} src={logo}></img></a>
          </div>
          <div className="flex flex-col items-start p-8">  
          <div className=" items-center rounded-full border border-black p-1 mr-3">
        <input
          type="text"
          placeholder="Məhsul axtar"
          className="text-gray-500 placeholder-gray-500 focus:outline-none px-4 py-2 rounded-full flex-grow"
        />
        <button className="bg-[#5A639C] p-2 rounded-full">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.66671 2.33334C4.44505 2.33334 1.83337 4.94502 1.83337 8.16668C1.83337 11.3883 4.44505 14 7.66671 14C10.8884 14 13.5 11.3883 13.5 8.16668C13.5 4.94502 10.8884 2.33334 7.66671 2.33334ZM0.833374 8.16668C0.833374 4.39273 3.89276 1.33334 7.66671 1.33334C11.4407 1.33334 14.5 4.39273 14.5 8.16668C14.5 9.87368 13.8741 11.4345 12.8393 12.6321L15.0203 14.8131C15.2155 15.0084 15.2155 15.325 15.0203 15.5202C14.825 15.7155 14.5084 15.7155 14.3132 15.5202L12.1322 13.3393C10.9345 14.3741 9.37372 15 7.66671 15C3.89276 15 0.833374 11.9406 0.833374 8.16668Z" fill="white"/>
</svg>
        </button>
        
      </div>
      
            <a href="/" className="block px-4 py-2 hover:bg-gray-100">Ana səhifə</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">Məhsullar</a>
                <button
                  onClick={toggleProductsSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isProductsOpen && (
                 <div className="relative flex flex-col w-full">
                 <div className="flex items-center w-full">
                   <a href="/modeller" className="block px-4 py-2 hover:bg-gray-100">Modellər</a>
                   <button
                     onClick={toggleModelsSubMenu}
                     className="ml-auto focus:outline-none"
                   >
                     <FaChevronDown />
                   </button>
                 </div>
                 {isModelsOpen && (
                   <div className="ml-4">
                     <a href="/sofa" className="block px-4 py-2  hover:bg-gray-100">Sofa</a>
                     <a href="/top" className="block px-4 py-2  hover:bg-gray-100">Top</a>
                     <a href="/komfort" className="block px-4 py-2  hover:bg-gray-100">Komfort</a>
                     <a href="/klassik" className="block px-4 py-2 hover:bg-gray-100">Klassik</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">Şezlonq</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">Kuşetka</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">Yastıq</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">Puff</a>
                   </div>
                 )}
               </div>
                
              )}
              {isProductsOpen && (
                 <div className="relative flex flex-col w-full">
                 <div className="flex items-center w-full">
                   <a href="/services" className="block px-4 py-2 hover:bg-gray-100">{t("Heyvanlar üçün")}</a>
                   <button
                     onClick={toggleAnimalsSubMenu}
                     className="ml-auto focus:outline-none"
                   >
                     <FaChevronDown />
                   </button>
                 </div>
                 {isAnimalsOpen && (
                   <div className="ml-4">
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">İtlər üçün</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">Pişiklər üçün</a>
                   </div>
                 )}
               </div>
                
              )}
              {isProductsOpen && (
                 <div className="relative flex flex-col w-full">
                 <div className="flex items-center w-full">
                   <a href="/" className="block px-4 py-2 hover:bg-gray-100">Doldurucu</a>
                   <button
                     onClick={toggleDoldurucuSubMenu}
                     className="ml-auto focus:outline-none"
                   >
                     <FaChevronDown />
                   </button>
                 </div>
                 {isDoldurucuOpen && (
                   <div className="ml-4">
                     <a href="/" className="block px-4 py-2  hover:bg-gray-100">50 L</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">100 L</a>
                     <a href="/" className="block px-4 py-2 hover:bg-gray-100">200 L</a>
                   </div>
                 )}
               </div>
                
              )}
            </div>
            <a href="/about" className="block px-4 py-2 hover:bg-gray-100">Haqqımızda</a>
            <a href="/partners" className="block px-4 py-2 hover:bg-gray-100">Tərəfdaşlarımız</a>
            <a href="/contact" className="block px-4 py-2 hover:bg-gray-100">Əlaqə</a>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default DashboardHeader;













