import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  userInfo: [],
  products: [],
  checkedBrands: [],
  checkedCategorys: [],
};

export const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => 
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.uzluk === action.payload.uzluk &&
          item.color === action.payload.color
      );
      
      if (item) {
        item.quantity += action.payload.quantity;
        item.selected = action.payload.selected;
      } else {
        state.products.push({ ...action.payload, selected: true }); // Add `selected` property
      }
      toast.success("Məhsul səbətə əlavə olundu");
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => 
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.uzluk === action.payload.uzluk &&
          item.color === action.payload.color
      );
      if (item) {
        item.quantity++;
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => 
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.uzluk === action.payload.uzluk &&
          item.color === action.payload.color
      );
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },
    deleteItem: (state, action) => {
      state.products = state.products.filter(
        (item) => 
          item.id !== action.payload.id ||
          item.size !== action.payload.size ||
          item.uzluk !== action.payload.uzluk ||
          item.color !== action.payload.color
      );
      toast.error("Məhsul səbətdən çıxarıldı");
    },
    resetCart: (state) => {
      state.products = [];
      toast.error("Səbətiniz boşaldıldı");
    },
    toggleBrand: (state, action) => {
      const brand = action.payload;
      const isBrandChecked = state.checkedBrands.some(
        (b) => b.id === brand.id
      );

      if (isBrandChecked) {
        state.checkedBrands = state.checkedBrands.filter(
          (b) => b.id !== brand.id
        );
      } else {
        state.checkedBrands.push(brand);
      }
    },
    toggleCategory: (state, action) => {
      const category = action.payload;
      const isCategoryChecked = state.checkedCategorys.some(
        (b) => b.id === category.id
      );

      if (isCategoryChecked) {
        state.checkedCategorys = state.checkedCategorys.filter(
          (b) => b.id !== category.id
        );
      } else {
        state.checkedCategorys.push(category);
      }
    },
  },
});

export const {
  addToCart,
  increaseQuantity,
  drecreaseQuantity,
  deleteItem,
  resetCart,
  toggleBrand,
  toggleCategory,
} = orebiSlice.actions;

export default orebiSlice.reducer;


